import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import axios from 'axios';

export default function ViewPyLogs() {

    const [clients, setClients] = useState([]);


   useEffect(() => {
        fetch('/py-logs')
            .then(response => response.json())
            .then(resp => {
                // const filteredClients = resp?.users;
                // setClients(filteredClients);

                const filteredUsers = resp?.users.filter(user => user.logs && user.logs.length > 0);
                console.log("filteredUsers",filteredUsers)
               
                setClients(filteredUsers)
                
            })
            .catch(error => console.error('Error fetching client data:', error));
    }, []);


    const deletePyLog = async (id) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this Python log?');
        if (!confirmDelete) {
          return; // Exit if user cancels
        }
        try {
          await axios.delete(`/deletepylog/${id}`);
          const filteredClients = clients.filter((user) => user._id !== id); // Update local state
           setClients(filteredClients);
            
         
        } catch (error) {
          console.error('Error deleting user:', error);
          alert('Failed to delete user');
        }
      };
   

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        };
        return date.toLocaleString('en-US', options).replace(',', '');
    };


    const columns = [

        {
            name: 'Log Time',
            selector: row => formatTimestamp(row.created_at),
            sortable: true,
            width: '200px',
        },
     
     
      {
          name: 'View Log',
          cell: row => (
            <Link to={`/view-py-logdetails/${row._id}`} target='_blank' style={{ 
              textDecoration: 'underline', 
              color: 'blue',
              padding: '10px',
              borderRadius: '8px'
            }}>View Log</Link>
          ),
          sortable: true,
          width: '200px',
      },
      {
        name: 'Action',
        cell: row => (
          <div className="dropdown">
            <a className="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle" href="#" role="button" data-toggle="dropdown">
              <i className="dw dw-more"></i>
            </a>
            <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
              
              <button className="dropdown-item" onClick={() => deletePyLog(row?._id)}><i className="dw dw-delete-3"></i> Delete</button>
            </div>
          </div>
        ),
    },
   
  ];

  const customStyles = {
      rows: {
          style: {
              marginBottom: '10px',
              padding: '10px 0',
              borderBottom: '1px solid #ccc',
          },
      },
      tableWrapper: {
          style: {
              overflowX: 'auto',
          },
      },
  };
  
  


  return (
    <div className="main-container">
            <div className="pd-ltr-20 xs-pd-20-10">
                <div className="min-height-200px">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <div className="title">
                                    <h4>View Python LLM Logs</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-box mb-30">
                        <div className="pd-20">
                        </div>
                        <div className="pb-20">
                          
                            <DataTable
                                columns={columns}
                                data={clients} // Use filtered data
                                pagination
                                paginationRowsPerPageOptions={[50,100,150,200,250,300]}
                                paginationPerPage={50} // Set default records per page to 25
                                highlightOnHover
                                customStyles={customStyles}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}
