import React, { useEffect, useState, useRef } from "react";
import UseToast from "../UseToast";
import UseTranslationData from "../UseTranslationData";

const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
recognition.continuous = false; // Change to false for single recognition session
recognition.interimResults = true;

const AudioToTextJavaScript = ({
  siteBrandColor,
  onstartConverting,
  onstopRecording,
  translationData,
  aiFormData,
  onplayPromoMessage,  
  onsessionInfo,
  // onSpeechRecognitionEnd,
  onstartspeech,
  rizwanScriptStep,
  setonstartspeech,
  rizwanScriptStepEnglishRef,
  rizwanButtonFun,
  onfaceDetectionStartSpeech,
  setfaceDetectionStartSpeech,
  isInRangeRefFun,
  onplayUserSilenceMsg,
  playRandomSpeech,
  idealMode,
  resetLoopVideoBtnCase,
  waitingTime
}) => {
  const [currentRecognition, setCurrentRecognition] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const silenceTimer = useRef(null);
  const inactivityTimer = useRef(null);
  const recognitionTimer = useRef(null); // Timer to stop recognition after 10 seconds
  const currentRecognitionRef = useRef("");
  const onsessionInfoRef = useRef(onsessionInfo);
  const rizwanScriptStepRef= useRef(rizwanScriptStep);

  const { showSuccessToast, dismissToast } = UseToast();
  const [currentRecognitionLang, setCurrentRecognitionLang] = useState("");
  const currentRecognitionLangListenRef = useRef('');
  const currentRecognitionLangRef = useRef("");
  const [currentText, setCurrentText] = useState("");
  const words = "Welcome! I've let Amy know you are here. Is there anything else I can help you with?".split(" ");
  const timerRef = useRef(null); 
  const [currentWordIndex, setCurrentWordIndex] = useState(0);


   // Handle microphone input setup
   useEffect(() => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        // Code to start recognition if microphone is available
        recognition.lang = "en-US";
        recognition.onresult = handleSpeechRecognition;
        recognition.onend = () => {
          console.log("Recognition ended.");
          if (isRecording) recognition.start();
        };

        recognition.onerror = function(event) {
          console.log("microphone error",event.error);
      };
      })
      .catch((error) => {
        console.error("Microphone access denied", error);
      });
  }, [isRecording]);
  

  useEffect(()=>{
    if(onstartspeech){ 
      playRizwanEvent();
  
    }
  },[onstartspeech])

  useEffect(()=>{
    rizwanScriptStepRef.current=rizwanScriptStep;
  },[rizwanScriptStep])


  useEffect(() => {
    if (rizwanScriptStepEnglishRef) {
      setCurrentWordIndex(0); // Reset word index when it becomes true
      timerRef.current = setInterval(() => {
        setCurrentWordIndex((prevIndex) => prevIndex + 1);
      }, 380); // Adjust the speed here (500ms = 0.5 second per word)
    } else {
      clearInterval(timerRef.current);
    }

    return () => {
      clearInterval(timerRef.current); // Clean up on unmount
    };
  }, [rizwanScriptStepEnglishRef]);

  useEffect(() => {
    setCurrentText(words.slice(0, currentWordIndex).join(" "));
  }, [currentWordIndex]);


  useEffect(() => {
    let timeoutId;

    if (aiFormData.enable_face_detection === 'yes' && !isInRangeRefFun) {
      // Set a timeout to wait for 2 seconds
      timeoutId = setTimeout(() => {
        // Re-check the condition to ensure user is still out of range
        if (!isInRangeRefFun) {
          const stopRecording = document.getElementById("stoprecording");

          console.log("click")
          // if (stopRecording) {
          //   stopRecording.click(); // Trigger the stop recording action
          // }
        }
      }, 2000); // Wait for 2 seconds
    }

    // Cleanup function to clear timeout if isInRangeRefFun changes back to true
    return () => clearTimeout(timeoutId);
  }, [isInRangeRefFun]); 


  useEffect(()=>{

    // console.log("isInRangeRefFun",isInRangeRefFun)
 
     if(onfaceDetectionStartSpeech && isInRangeRefFun){

      
      recognition.stop();
 
         const ai_starttalk = document.getElementById("ai_starttalk");
         if (ai_starttalk) {
             ai_starttalk.click();
         }    
 
     }
 
   },[onfaceDetectionStartSpeech])


  useEffect(() => {

    currentRecognitionRef.current = currentRecognition;
    
  }, [currentRecognition]);


  useEffect(() => {
    currentRecognitionLangRef.current = currentRecognitionLang;
  
    const listenWord=UseTranslationData(aiFormData,currentRecognitionLang.split('-')[0]);
    currentRecognitionLangListenRef.current = listenWord?.listening_text;
  }, [currentRecognitionLang]);
  
    useEffect(() => {
      onsessionInfoRef.current = onsessionInfo;
    }, [onsessionInfo]);

  

  const startRecording = () => {
   
    if(aiFormData?.enable_face_detection!=='yes' && idealMode){

      resetLoopVideoBtnCase();

      return;

    }

    setIsRecording(true);  
    

    recognition.start();
    //startSilenceTimer();
    startRecognitionTimer(); // Start 10-second timer
   
    if(aiFormData.enable_rizwan_event!=='yes' && aiFormData.enable_face_detection!=='yes'){
      document.getElementById("ai_starttalk").style.display = 'none';
      document.getElementById("stoprecording").style.display = 'block';
    }
    currentRecognitionRef.current = null;
  };

  const stopRecording = () => {
    setIsRecording(false);
    recognition.stop();
    clearSilenceTimer();
    clearRecognitionTimer(); // Clear the recognition timer

  
      dismissToast();
      
    //showWarningToast("Recording stopped.");
    const finalRecognition = currentRecognitionRef.current;
    const finalRecognitionLang = currentRecognitionLangRef.current;

    if(aiFormData.enable_face_detection==='yes' && !isInRangeRefFun){
      currentRecognitionRef.current = null;
      setfaceDetectionStartSpeech();
      return;
    } 

    if(finalRecognition){
      onstopRecording(finalRecognition,finalRecognitionLang.split('-')[0]);

      if(aiFormData.enable_face_detection==='yes' && aiFormData.enable_rizwan_event!=='yes'){
        setfaceDetectionStartSpeech();  

      }
    }else{

      playUserSilenceMessage();

    }

    
    if(aiFormData.enable_rizwan_event!=='yes' && aiFormData.enable_face_detection!=='yes'){
      document.getElementById("ai_starttalk").style.display = 'block';
      document.getElementById("stoprecording").style.display = 'none';

    }
  };

  const handleSpeechRecognition = (event) => {
    clearSilenceTimer(); // Reset silence timer upon hearing speech
    let finalTranscript = '';

    console.log("event",event)


    for (let i = event.resultIndex; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {
            finalTranscript += stringConversion(event.results[i][0].transcript);
            setCurrentRecognitionLang(event.target.lang);
            currentRecognitionLangRef.current= event.target.lang;
        }
    }

    if (finalTranscript) {
        setCurrentRecognition(finalTranscript);
        currentRecognitionRef.current = finalTranscript; // Update ref here for latest value
        resetInactivityTimer();

        const totalTextWordCount = finalTranscript.split(/\s+/).length;

      if(totalTextWordCount <=2){       

        playRandomSpeech(onsessionInfoRef.current);

        setIsRecording(false);
        recognition.stop();
        clearSilenceTimer();
        clearRecognitionTimer(); // Clear the recognition timer
        dismissToast();

        if(aiFormData.enable_rizwan_event!=='yes' && aiFormData.enable_face_detection!=='yes'){
          document.getElementById("ai_starttalk").style.display = 'block';
          document.getElementById("stoprecording").style.display = 'none';
    
        }

        if(aiFormData.enable_face_detection==='yes'){

            setfaceDetectionStartSpeech();

        }

      }else{

        stopRecording(); // Stop recording after final speech is captured
      }
    }
};

const stringConversion = (searchText) => {
  // Get individual replacements from translationData
  const textArray = translationData?.text_replace.split('\n').filter(item => item.trim() !== '');

  // Split the search text into words, using regular expressions to account for line breaks or special characters
  const wordsToSearch = searchText.split(/\s+/);

  console.log("Original wordsToSearch", wordsToSearch);

  // Function to normalize words by removing special characters and converting to lowercase
  const normalizeWord = (word) => word.replace(/[^\w\s]/gi, '').toLowerCase();

  // Update each word in the search text if it matches any translationData replacements
  const updatedText = wordsToSearch.map(word => {
    const normalizedWord = normalizeWord(word);
    
    // Iterate through textArray to find matching replacements
    for (let item of textArray) {
      const [lhs, rhs] = item.split('|').map(part => part.trim());
      const normalizedLHS = normalizeWord(lhs);

      // If a normalized word matches normalized LHS, replace it with RHS
      if (normalizedLHS === normalizedWord) {
        return rhs; // Replace matched word
      }
    }
    return word; // Return unchanged word if no match found
  }).join(' ');

  console.log("Updated Text", updatedText);
  return updatedText;
};


  // const startSilenceTimer = () => {
  //   if (silenceTimer.current) clearTimeout(silenceTimer.current);
  //   silenceTimer.current = setTimeout(() => {
  //     playUserSilenceMessage();
  //   }, 10000); // 10 seconds silence timeout
  // };

  const clearSilenceTimer = () => {
    if (silenceTimer.current) clearTimeout(silenceTimer.current);
  };

  const startRecognitionTimer = () => {

    showSuccessToast("Speak Now...");

    if (recognitionTimer.current) clearTimeout(recognitionTimer.current);
    recognitionTimer.current = setTimeout(() => {
      stopRecording(); // Stop recording after 10 seconds
    }, 12000); // 10 seconds
  };

  const clearRecognitionTimer = () => {
    if (recognitionTimer.current) clearTimeout(recognitionTimer.current);
  };

  const resetInactivityTimer = () => {
    if (inactivityTimer.current) clearTimeout(inactivityTimer.current);
    inactivityTimer.current = setTimeout(() => {
      playPromoMessage();
    }, aiFormData?.promo_time_limit * 60 * 1000); // Promo timeout based on aiFormData
  };

  const playPromoMessage = () => {
  
    onplayPromoMessage(onsessionInfoRef.current);
    resetInactivityTimer()
  };

  const playUserSilenceMessage = () => {

  
    if(aiFormData.enable_rizwan_event!=='yes'){

      onplayUserSilenceMsg(onsessionInfoRef.current);

      if(aiFormData.enable_face_detection==='yes'){
        setfaceDetectionStartSpeech();
      }          
    
    }
  };


  const playRizwanEvent=()=>{

    if(aiFormData.enable_rizwan_event==='yes' && aiFormData?.enable_rizwan_manual_script === 'yes'  &&  rizwanScriptStepRef.current === 2){

    showSuccessToast('Please Speak Now!');

    setTimeout(() => {
      //console.log("Triggering runManualRizwan...");
      runManualRizwan();
    }, 5000); 

  }else{


    const ai_starttalk = document.getElementById("ai_starttalk");
      if (ai_starttalk) {
          ai_starttalk.click();
      }

  }
}

const runManualRizwan=()=>{   

  console.log("Silence detected. Stopping the recording...");
  const finalRecognition = "adasdasdasdsadas"; // Accessing the current recognition value from the ref

  const finalRecognitionLang = currentRecognitionLangRef.current;

  console.log("finalRecognitionLang", finalRecognitionLang); // This should now log the correct value


  if(finalRecognition ){   
    onstopRecording(finalRecognition,'en'); 

    currentRecognitionRef.current = "";
    setCurrentRecognition('');
    setonstartspeech();   

  }
}


const rizwanButtonFunRun=()=>{

  if(rizwanScriptStepRef.current===1 && aiFormData.enable_rizwan_event==='yes'){

  const rizwanStatus = aiFormData.enable_rizwan_event==='yes' ? true : false;
  rizwanButtonFun(onsessionInfoRef.current,rizwanStatus);

  }


if(rizwanScriptStepRef.current===2 && aiFormData.enable_rizwan_event==='yes'){
    
  setonstartspeech();
}

}

  return (
    <div>
      {aiFormData?.enable_speech_btn==='yes' && (
        <>

       
         
          <button type="button" className="ai_starttalk" id="ai_starttalk" style={{ 
            backgroundColor: siteBrandColor, 
            display: aiFormData.enable_rizwan_event==='yes' || aiFormData.enable_face_detection==='yes' ? 'none' : ''
            
          }}  
          onClick={startRecording}>
          {waitingTime ? 'Please Wait...' : translationData?.ask_your_question}
    
          </button> 
      
    
          <button type="button" className="ai_starttalk" id="stoprecording" style={{ 
            backgroundColor: siteBrandColor, 
            display: aiFormData.enable_rizwan_event==='yes' ? 'none' : ''
            
            }} onClick={stopRecording}>
          {currentRecognitionLangListenRef.current ? currentRecognitionLangListenRef.current: 'Listening...'}
          </button>


          {(rizwanScriptStepRef.current===1 || rizwanScriptStepRef.current===2) && aiFormData.enable_rizwan_event==='yes' && aiFormData?.enable_rizwan_btn==='yes' && (

            <button type="button" className="ai_starttalk"  style={{ 
              backgroundColor: siteBrandColor           
              
              }} onClick={()=>rizwanButtonFunRun()}>
            {rizwanScriptStep===1 ? aiFormData?.enable_rizwan_btn_text_1 : aiFormData?.enable_rizwan_btn_text
            }
            </button>
            )}


         
          
          <div id="textresponse" style={{ fontSize: aiFormData.enable_rizwan_event==='yes' ? '42px' : '20px'}}>
      {
        aiFormData.speech_text_response === 'yes'  && (
          aiFormData.enable_rizwan_event === 'yes' && rizwanScriptStepEnglishRef
            ? currentText
            : aiFormData.enable_rizwan_event === 'yes' && !rizwanScriptStepEnglishRef ? '' : currentRecognitionRef.current
  
            )
            
        
  
          
      }

      </div>
     

     
      </>

      )}
      
    </div>
  );
};

export default AudioToTextJavaScript;
