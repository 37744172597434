import React, { useEffect, useRef, useState } from 'react';
import * as faceapi from 'face-api.js';

const FaceDetection = ({
  onplayPromoMessage,
  onsessionInfo,
  onstartspeech,
  styles,
  aiFormData,
  rizwanScriptStep,
  isInRangeRef
}) => {
  const videoRef = useRef(null);
  const [distance, setDistance] = useState(null);
  const [isInRange, setIsInRange] = useState(false);
  const enterRangeTimeRef = useRef(null);
  const hasTriggeredRef = useRef(false);
  const onsessionInfoRef = useRef(onsessionInfo);
  const rizwanScriptStepRef = useRef(rizwanScriptStep);
  const isFaceDetectionPaused = useRef(null);

  useEffect(() => {
    onsessionInfoRef.current = onsessionInfo;
  }, [onsessionInfo]);

  useEffect(() => {
    rizwanScriptStepRef.current = rizwanScriptStep;
  }, [rizwanScriptStep]);

  // Calibration values
  const referenceDistance = 1; // known distance in meters
  const referenceFaceWidthInPixels = 100; // calibrate for your setup
  const focalLength = referenceFaceWidthInPixels * referenceDistance;


  useEffect(()=>{
        console.log("render");
  },[])

  useEffect(() => {
    const loadModels = async () => {
      await faceapi.nets.tinyFaceDetector.loadFromUri('/models');
      await faceapi.nets.ssdMobilenetv1.loadFromUri('/models');
      await faceapi.nets.faceLandmark68Net.loadFromUri('/models');
      await faceapi.nets.faceRecognitionNet.loadFromUri('/models');
    };

    const startVideo = () => {
      // **Conditional Video Start** - Only set srcObject if it hasn’t been assigned before
      if (!videoRef.current.srcObject) {
        navigator.mediaDevices
          .getUserMedia({ video: { facingMode: 'user' } })
          .then((stream) => {
            videoRef.current.srcObject = stream;
          })
          .catch((err) => console.error('Error accessing webcam: ', err));
      }
    };

    loadModels().then(startVideo); // **Single Model Load** - Ensures models are loaded only once

    let resetTimeout = null;
    let resetTimeoutwalk = null;

    const detectFaces = async () => {
      if (!isFaceDetectionPaused.current && videoRef.current && videoRef.current.readyState === 4) {
        const detections = await faceapi
          .detectAllFaces(videoRef.current, new faceapi.TinyFaceDetectorOptions())
          .withFaceLandmarks();

        if (detections.length === 0) {
          // **Debounce Reset** - Avoid resetting too frequently by using a delay
          if (isInRange && !resetTimeout) {
            resetTimeout = setTimeout(() => {
              setDistance(null);
              setIsInRange(false);
              enterRangeTimeRef.current = null;
              isInRangeRef.current = false;
              resetTimeout = null;
            }, 3000);
          }
          return;
        }
         else {
          if (resetTimeout) {
            clearTimeout(resetTimeout);
            resetTimeout = null;
          }
        }

        const splitTimeRange = aiFormData?.fecedetection_time_range.split(';').map(Number);

        const inRange = detections.some((detection) => {
          const faceWidth = detection.detection.box.width;
          const distanceEstimate = (focalLength / faceWidth).toFixed(2);
          // **Conditional State Update** - Only update distance if the value changes
          if (distance !== distanceEstimate) setDistance(distanceEstimate);
          return (
            distanceEstimate >= splitTimeRange[0] &&
            distanceEstimate <= splitTimeRange[1]
          );
        });

        if (inRange) {
          if (!isInRange) {
            setIsInRange(true);
            enterRangeTimeRef.current = Date.now();
            hasTriggeredRef.current = false;
            if (resetTimeoutwalk) {
              clearTimeout(resetTimeoutwalk);
              resetTimeoutwalk = null;
            }
          } else if (
            !hasTriggeredRef.current &&
            Date.now() - enterRangeTimeRef.current >= aiFormData?.time_rane * 1000
          ) {
            triggerFunction();
            hasTriggeredRef.current = true;
          }
        } else if (isInRange && !resetTimeoutwalk) {
          resetTimeoutwalk = setTimeout(() => {
            setIsInRange(false);
            enterRangeTimeRef.current = null;
            resetTimeoutwalk = null;
          }, 5000);
        }

        isInRangeRef.current = inRange;
      }
    };

    const interval = setInterval(detectFaces, 1000); // **Increased Detection Interval** - Reduced frequency to 1000ms

    return () => {
      clearInterval(interval);
      if (resetTimeout) clearTimeout(resetTimeout);
      if (resetTimeoutwalk) clearTimeout(resetTimeoutwalk);
    };
    
  }, [isInRange]);

  const triggerFunction = () => {
    if (rizwanScriptStepRef.current === 2 && aiFormData.enable_rizwan_event === 'yes') {
      onstartspeech();
    }

    if (rizwanScriptStepRef.current === 1 && aiFormData.enable_rizwan_event === 'yes') {
      const rizwanStatus = aiFormData.enable_rizwan_event === 'yes';
      setDistance(null);
      onplayPromoMessage(onsessionInfoRef.current, rizwanStatus);
      isFaceDetectionPaused.current = true;
      hasTriggeredRef.current = true;
      setIsInRange(false);
      enterRangeTimeRef.current = null;

      setTimeout(() => {
        isFaceDetectionPaused.current = null;
      }, 12000);
    }

    if (aiFormData.enable_rizwan_event !== 'yes' && !hasTriggeredRef.current) {
      console.log("user entered in zone")
      onplayPromoMessage(onsessionInfoRef.current);
    }
  };

  return (
    <div id="face_detection_location" style={styles}>
      <video
        ref={videoRef}
        autoPlay
        muted
        width="180"
        height="140"
        style={{
          visibility: aiFormData.disable_camera_front === 'yes' ? 'hidden' : 'visible'
        }}
      />
      {distance && (
        <div>
          <h3 style={{ fontSize: '10px', visibility: aiFormData.disable_camera_front === 'yes' ? 'hidden' : 'visible' }}>
            Distance from Screen: {distance} meters
          </h3>
        </div>
      )}
    </div>
  );
};

export default FaceDetection;
