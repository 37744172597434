import React, { useState, useEffect,useRef,useCallback } from 'react';
import MainImageLogo from './MainImageLogo';
import HeaderLogo from './HeaderLogo';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useParams } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { hideComponents,avtarsesssionfun } from '../store';
import AudioToText from './backend/AudioToText';
import AudioToTextJavaScript from './backend/AudioToTextJavaScript';

import Cookies from 'js-cookie';
import FaceDetection from './backend/FaceDetection';
import UseToast from './UseToast';
import UseTranslationData from './UseTranslationData';
import video from '../assets/videos/4k.mp4';
import { format, toZonedTime } from 'date-fns-tz';
import { parse } from 'date-fns'; // Import parse from date-fns

export default function AiFormUpdateProd() {
    const [aiFormData, setAIFormData] = useState(null);
    const [translationData, setTranslationData] = useState(null);
    const [defaultLang, setDefaultLang] = useState(localStorage.getItem('default_lang') || 'en');
    const [siteBrandColor, setSiteBrandColor] = useState('');
    const [languages, setLanguages] = useState([]);
    const serverUrl='https://api.heygen.com';
    const [sessionInfo, setSessionInfo] = useState({});
    const [peerConnection, setPeerConnection] = useState({});
    const mediaRef = useRef(null); // Reference to the media element
    const [mediaCanPlay, setMediaCanPlay] = useState(false);
    const { transcript, interimTranscript, finalTranscript, resetTranscript } = useSpeechRecognition();
    const [recognitionRunning, setRecognitionRunning] = useState(false);
    const [speechText,setSpeechText]=useState("");
    const silenceTimer = useRef(null);
    const inactivityTimer = useRef(null);
    const rizwanScriptStepRef = useRef(null);
    const rizwanScriptStepEnglishRef = useRef(null);
    const [sessionId,setSessionId]=useState(null);
    const [startSpeech,setStartSpeech]= useState(false);
  
    const { avtarsesssion } = useSelector((state) => state.visibility);
    const onspeechTextRef = useRef(null);
  
    const [rizwanScriptStep,setRizwanScriptStep]= useState(1);
    const [speechDurationInMs, setSpeechDurationInMs] = useState(0); // Store the speech duration in milliseconds
    const [isSpeaking, setIsSpeaking] = useState(false);
  
    const { showSuccessToast, showWarningToast,dismissToast } = UseToast(); // Use the custom hook

    const [isAvatarVisible, setIsAvatarVisible] = useState(false); // State to manage visibility

    const numberRef = useRef([]); // Initialize useRef with an empty array
   

    const [loaderStart,setLoaderStart]=useState(false);

    const [showButton, setShowButton] = useState(false);

    const [faceDetectionStartSpeech,setFaceDetectionStartSpeech] = useState(false);

    const isInRangeRef = useRef(false); // Create a ref to hold isInRange value
    const [idealMode,setIdealMode] = useState(false);
    const [isTimeRange,setIsTimeRange] = useState(false);

    const [waitingTime,setWaitingTime] = useState(false);

    const videoRef = useRef(null);
    const isTimeRangeRef = useRef(null);
    const startTime = 5; // Start from 5 seconds
    const timeoutIdRef = useRef(null); // To store the timeout ID

    const resetCameraRef = useRef(null); // To store the timeout ID
    const [key, setKey] = useState(0);
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [wasOffline, setWasOffline] = useState(false); // Tracks if the user was offline
    const disconnectTimer = useRef(null); 
  
    const { client_url } = useParams();
    const dispatch = useDispatch();



    const handleReconnect = async() => {
    console.log("Reconnected to the internet! Performing actions...");
  
    // Dismiss the toast or perform other actions
    dismissToast();

       

  
    // Reload the page after 2-3 seconds
    setTimeout(async() => {
      await recordLog('Reconnected to the internet!','Reconnected to the internet!','pending','non-rizwan');   
      window.location.reload();
    }, 5000); // 2000 milliseconds = 2 seconds
  };
  

  const updateOnlineStatus = async() => {
    if (navigator.onLine) {
      // Clear the disconnect timer and handle reconnection
      clearTimeout(disconnectTimer.current);
      setIsOnline(true);

      if (wasOffline) {
        handleReconnect(); // Call the function when transitioning to online
        setWasOffline(false); // Reset offline flag
      }
    } else {
     
     //await recordLog('Disconnected from the internet!','Disconnected from the internet!','pending','non-rizwan');  
     
      // Wait for 30 seconds before marking as offline
      disconnectTimer.current = setTimeout(async() => {

         setIsOnline(false);
        setWasOffline(true); // Mark as offline
        console.log("Disconnected from the internet!");
        showWarningToast("Sorry, we are experiencing temporary technical difficulties. please come back in a few minutes.");
        
        
      }, 5000); // 30,000 milliseconds = 30 seconds
    }
  };

  useEffect(() => {
    // Add event listeners for online and offline events
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    // Cleanup listeners on component unmount
    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
      clearTimeout(disconnectTimer.current);
    };
  }, [wasOffline]); // Dependency ensures updates when `wasOffline` changes
  
    useEffect(()=>{  
      rizwanScriptStepRef.current=rizwanScriptStep;  
      
      if(rizwanScriptStep!==2){
        rizwanScriptStepEnglishRef.current=null;

      }
  
    },[rizwanScriptStep])
  
  
    
    useEffect(() => {
  
      const getColor = async () => {
        try {
          const response = await fetch(`/auth/aiform/frontend/${client_url}`);
  
          const data = await response.json();
          if (!response.ok) {
            
             alert(`Error: ${data.message}`);
          }else{
  
         
          const aiForm = data.aiForms[0];
          setAIFormData(aiForm);          
  
          const translation =  UseTranslationData(aiForm, defaultLang);
          setTranslationData(translation);
  
          if (translation) {
            setSiteBrandColor(translation.site_brand_color);
            localStorage.setItem('site_brand_color', translation.site_brand_color);
          }
  
          // Set languages
          setLanguages(aiForm.selected_lang_values);
  
          // Position the avatar logo
         // positionAvatarLogo(aiForm.avtar_page_image_location);
          
          // Handle live chat visibility
          handleLiveChatVisibility(aiForm, translation);

          setTimeout(() => {
            setShowButton(true);
          }, aiForm?.time_rane_splash* 1000); // 5 seconds  
  
          
         
  
        }
          
        } catch (error) {
          console.error('Error fetching AI form data:', error);
        }
      };
  
      getColor();
      dispatch(hideComponents({ showHeader: false, showSidebar: false }));
  
      
  
  
      // dispatch(hideComponents());
  
  
  
    }, [defaultLang,dispatch]);
  
   
    
  
    const positionAvatarLogo = (location) => {
      let style = {};
      switch (location) {
        case 'top-right':
          style = { top: '0px', right: '5px' };
          break;
        case 'top-left':
          style = { top: '0px', left: '5px' };
          break;
        case 'bottom-right':
          style = { bottom: '0px', right: '5px' };
          break;
        case 'bottom-left':
          style = { bottom: '0px', left: '5px' };
          break;
        default:
          break;
      }
      return style;
    };
  
    const handleLiveChatVisibility = (aiForm, translation) => {
      if (aiForm.live_chat_enable === 'yes') {
        return (
            <>
          <a
            href={translation?.live_chat_url}
            style={{
              display: 'block',
              backgroundColor: translation?.site_brand_color,
              fontWeight: 'bold',
            }}
            target={aiForm.open_chat_new_tab === 'yes' ? '_blank' : '_self'}
            id="ai_live_chat"
          >
            {translation?.live_chat_button_text}
          </a>
          </>
        );
      }
      return null;
    };
  
    const handleLanguageChange = (lang) => {
      
  
      localStorage.setItem('default_lang', lang);
      setDefaultLang(lang);
    };
  
  
  
    const renderLanguageButtons = () => {
      return languages?.map((item) => (
        <div key={item.value} className={`${item.value}_language_sec language_btn`}>
          <button
            type="button"
            className={item.value === defaultLang ? 'current_lang' : ''}
            style={{
              backgroundColor: item.value === defaultLang ? siteBrandColor : '#fff',
              border: `1px solid ${siteBrandColor}`,
              color: item.value === defaultLang ? '#fff' : siteBrandColor,
            }}
            onClick={() => handleLanguageChange(item.value)}
          >
            {item.title}
          </button>
        </div>
      ));
    };
  
  
    const getTranslationDataForLanguage=(aiForm, lang)=> {
      if (aiForm.translations && aiForm.translations[lang]) {
          return aiForm.translations[lang];
      } else {
          console.warn(`No translation data found for language: ${lang}`);
          return null;
      }
    }
  
  
    // Function to reset the silence timer
    const resetSilenceTimer = () => {
      if (silenceTimer.current) {
        clearTimeout(silenceTimer.current);
      }
      const blankSpaceTime = aiFormData?.blank_space; // Default to 5 seconds if not set
      silenceTimer.current = setTimeout(stopRecording, blankSpaceTime*1000);
    };
  
    // Function to reset the inactivity timer
    const resetInactivityTimer = () => {
      if (inactivityTimer.current) {
        clearTimeout(inactivityTimer.current);
      }
      const inactivityTimeLimit = aiFormData?.promo_time_limit; // Default to 10 seconds if not set
      inactivityTimer.current = setTimeout(playPromoMessage, inactivityTimeLimit* 60 * 1000);
    };
  
  
    useEffect(()=>{
  
      if(!aiFormData?.avtar_key || !aiFormData?.voice_key){
        return;
      }

      const intervalId = setInterval(() => {              
        checkTime();        
        
      }, 60000);

      if(aiFormData?.weeklyTiming)
        {
         const checkTime= filterCurrentDayAndCheckTime(aiFormData?.weeklyTiming);

         if(!checkTime){
          return;

         }else{
          setIsTimeRange(true);
         }
        }

          const checkLoop = getLoopSessionData(client_url);

        if(checkLoop && checkLoop.idealMode){
          setIdealMode(true);
          setIsAvatarVisible(true);
          scheduleFollowUp();
          return;
        }

     
  
      if(aiFormData.enable_rizwan_event!=='yes'){
           // createNewSession();

           

           const ai_concierge_generate = document.getElementById("ai_concierge_generate");
           if (ai_concierge_generate) {
            ai_concierge_generate.click();
           }
      }
  
    },[aiFormData])


     useEffect(()=>{

      isTimeRangeRef.current = isTimeRange;

    },[isTimeRange])


    const checkTime=async()=>{

      try {
        const response = await fetch(`/auth/aiform/frontend/${client_url}`);

        const data = await response.json();
        if (!response.ok) {
          
           alert(`Error: ${data.message}`);
        }else{

       
        const aiForm = data.aiForms[0];


        const checkTime= filterCurrentDayAndCheckTime(aiForm?.weeklyTiming);

        if (!checkTime && isTimeRangeRef.current) {
         
          window.location.reload();
        }
      

        if (checkTime && !isTimeRangeRef.current) {
         
          window.location.reload();
        }

        }
      }catch (error) {
        console.error('Error fetching AI form data:', error);
      }  
    }
  
    
    const createNewSession = async () => {
      try {
        const avatar = aiFormData?.avtar_key;
        const voice = aiFormData?.voice_key;

        setLoaderStart(!loaderStart);
  
        // Call the new interface to get the server's offer SDP and ICE server to create a new RTCPeerConnection
        const sessionData = await newSession('high', avatar, voice);
       
        const { sdp: serverSdp, ice_servers2: iceServers } = sessionData;
  
        console.log("sessionData",sessionData)
        setSessionInfo(sessionData);
  
  
        dispatch(avtarsesssionfun(sessionData));

        setIdealMode(false);
  
        // Create a new RTCPeerConnection
        const pc = new RTCPeerConnection({ iceServers });
  
        console.log("pc",pc)
        setPeerConnection(pc);
  
        // When audio and video streams are received, display them in the video element
        pc.ontrack = (event) => {
          console.log('Received the track');
          if (event.track.kind === 'audio' || event.track.kind === 'video') {
            if (mediaRef.current) {
              mediaRef.current.srcObject = event.streams[0]; // Set the stream dynamically
            }
          }
        };
        
  
        // When receiving a message, handle it
        pc.ondatachannel = (event) => {
          const dataChannel = event.channel;
          dataChannel.onmessage = onMessage;
        };
  
        // Set server's SDP as remote description
        const remoteDescription = new RTCSessionDescription(serverSdp);
        await pc.setRemoteDescription(remoteDescription);
  
        // Start the session and display it
        await startAndDisplaySession(pc,sessionData);
  
        
        
        document.body.classList.add('hide');

        setIsAvatarVisible(true); // Set visibility to true

  
        // const avatarSection = document.querySelector('.avtarsection');
        // avatarSection.style.display = 'flex';
  
       
  
  
      } catch (error) {
        console.error('Error creating session:', error);
        
      }
    };
  
    const newSession = async (quality, avatar_name, voice_id) => {
      const heygen_api_key = aiFormData?.heygen_api_key;
      const voice_rate = aiFormData?.voice_rate;
      const avtar_emotion = aiFormData?.avtar_emotion;
  
      try {
        const response = await fetch(`${serverUrl}/v1/streaming.new`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': heygen_api_key,
          },
          body: JSON.stringify({
            quality,
            avatar_name,
            voice: {
              voice_id,
              rate: parseFloat(voice_rate),
              emotion: avtar_emotion,
            },
            video_encoding: 'H264'
          }),
        });
  
        if (!response.ok) {
          console.error('Server error');
          return;
        }
  
        const data = await response.json();
        return data.data;
  
      } catch (error) {
        console.error('Error in newSession:', error);
      }
    };


     const reloadPage = () => {
  window.location.href = window.location.pathname + '?reload=' + new Date().getTime();
      };
  
    const startAndDisplaySession = async (pc,sessionData) => {
      if (!sessionInfo) {
        console.error('Please create a connection first');
        return;
      }
  
      try {    
          
  
        // Create and set local SDP description
        const localDescription = await pc.createAnswer();
        await pc.setLocalDescription(localDescription);
  
        // Handle ICE candidate
        pc.onicecandidate = async ({ candidate }) => {
          console.log('Received ICE candidate:', candidate);
          if (candidate) {
            await handleICE(sessionData.session_id, candidate.toJSON());
          }
        };
  
        // Handle ICE connection state change
        pc.oniceconnectionstatechange = () => {
          console.log(`ICE connection state changed to: ${pc.iceConnectionState}`);
  
          if(pc.iceConnectionState==='disconnected'){
  
           // reloadPage();

          const disconnectavtar = document.getElementById("disconnectavtar");
            if (disconnectavtar) {
              disconnectavtar.click();
            }   
    
           
            
          }
        };
  
        // Start the session
        await startSession(sessionData.session_id, localDescription);
  
        // Adjust jitter buffer target
        pc.getReceivers().forEach((receiver) => {
          receiver.jitterBufferTarget = 500;
        });
  
      } catch (error) {
        console.error('Error in startAndDisplaySession:', error);
      }
    };
  
    const handleICE = async (session_id, candidate) => {
      const heygen_api_key = aiFormData?.heygen_api_key;
  
      try {
        const response = await fetch(`${serverUrl}/v1/streaming.ice`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': heygen_api_key,
          },
          body: JSON.stringify({ session_id, candidate }),
        });
  
        if (!response.ok) {
          console.error('Server error');
          return;
        }
  
        const data = await response.json();
        return data;
  
      } catch (error) {
        console.error('Error in handleICE:', error);
      }
    };
  
    const startSession = async (session_id, sdp) => {
      const heygen_api_key =aiFormData?.heygen_api_key;
  
      try {
        const response = await fetch(`${serverUrl}/v1/streaming.start`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': heygen_api_key,
          },
          body: JSON.stringify({ session_id, sdp }),
        });
  
        if (!response.ok) {
          console.error('Server error');
          throw new Error('Server error');
        }
  
        const data = await response.json();
        return data.data;
  
      } catch (error) {
        console.error('Error in startSession:', error);
      }
    };
  
    const onMessage=(event)=> {
      const message = event.data;
      console.log('Received message:', message);
    }
  
    useEffect(() => {
      if (mediaRef.current) {
        const mediaElement = mediaRef.current;
    
        const handleLoadedMetadata = async () => {
          setMediaCanPlay(true);
          try {
            mediaElement.muted = false;
            // Attempt to play the media
            await mediaElement.play();   
            
           // mediaElement.muted = false;

            // Play only for Non Rizwan Case
             if(aiFormData.enable_rizwan_event!=='yes' && aiFormData.enable_face_detection!=='yes' && !waitingTime){

              await welcomemsg(sessionInfo.session_id);
              
            }

            if(isInRangeRef.current && aiFormData?.enable_face_detection==='yes' && !idealMode){
              // onplayPromoMessage(onsessionInfo);
        
              console.log("trigger");
        
              await welcomemsg(sessionInfo.session_id);
        
            }

            if(aiFormData.enable_rizwan_event!=='yes' && aiFormData.enable_face_detection!=='yes' && waitingTime){

              setWaitingTime(!waitingTime);
              const ai_starttalk = document.getElementById("ai_starttalk");
              if (ai_starttalk) {
                  ai_starttalk.click();
              }
              
            }
            
          } catch (error) {
            console.error("Autoplay failed, user interaction is required.", error);
          }
        };
  
  
        const handleMediaEnded = () => {
          console.log("Live stream speaking is done.");
          // Trigger the action you want to perform here
          handleAvatarSpeechDone();
        };
    
    
        // Attach the event listener
        mediaElement.onloadedmetadata = handleLoadedMetadata;
  
        mediaElement.onended = handleMediaEnded;
    
        // Cleanup the event listener on component unmount
        return () => {
          mediaElement.onloadedmetadata = null;
          mediaElement.onended = null;
        };
      }
    }, [mediaRef.current, sessionInfo]);
  
    const handleAvatarSpeechDone = () => {
      // This function will be called when the live stream ends
      console.log("Avatar live stream has finished speaking.");
      // You can perform any action here
      // For example, reset a state, start a new session, or update the UI
    };
  
  const welcomemsg=async(sessionID,rizwanStatus=false)=>{ 

   if(idealMode){

    const ai_concierge_generate = document.getElementById("ai_concierge_generate");
    if (ai_concierge_generate) {
     ai_concierge_generate.click();
    }
    cancelFollowUp();

     setSessionInfo({});


    return;

   }
  
    const predefinedText = rizwanStatus ? 'Hi Rizwan, welcome to our branch, how can I help you?' : translationData.avtar_welcome_text;
  
    await repeat(sessionID, predefinedText,true);   
    // setStartSpeech(!startSpeech);
    if(rizwanStatus){
      setRizwanScriptStep(prevStep => prevStep + 1);
  
    }
    
  }
  
  // repeat the text
  const repeat=async(session_id, text, action=false)=> {
    const heygen_api_key = aiFormData?.heygen_api_key;
    const default_lang = localStorage.getItem('default_lang') ? localStorage.getItem('default_lang') : 'en';

    const speed = 2.0;
    const pitch = 2.0;
  
    // Start translating text asynchronously
    let translatedTextPromise;
    // if (default_lang !== 'en') {
    //   translatedTextPromise = await translateText(text, default_lang);
    // } else {
    //   translatedTextPromise = Promise.resolve(text);
    // }
  
    // translatedTextPromise = Promise.resolve(text);

    if(!action){
      translatedTextPromise = text || 'Thank you for your question.';
    }else{

       let google_speech_error_data=translationData?.google_speech_error || 'Sorry I cant able to hear you can you speak again?';
        const google_speech_error_text = getRandomMessage(google_speech_error_data);
        translatedTextPromise = text || google_speech_error_text;
    }

    

  
    // Prepare the payload without waiting for the translation
    const payload = {
      session_id,
      speed,
      pitch
    };
  
    console.log("Request payload:", translatedTextPromise);
  
    try {
      // Await the translation and then add it to the payload
      payload.text = await translatedTextPromise;
  
      const response = await fetch(`${serverUrl}/v1/streaming.task`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Api-Key': heygen_api_key,
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error response:', errorData);
        throw new Error(`Server error: ${response.status}`);
      }
  
      const data = await response.json();
      console.log("data",data)

      const durationInMilliseconds = data.data.duration_ms + 500; // Get duration from API (in milliseconds)


      // Rizwan Case

      if(aiFormData.enable_rizwan_event==='yes'){       
    
      setTimeout(() => {
        onAvatarSpeakingDone();
      }, durationInMilliseconds);

      
      if(rizwanScriptStep===2){
        rizwanScriptStepEnglishRef.current=true;
      }else{
        rizwanScriptStepEnglishRef.current=null;
      }

      }

      // FaceDetection Case 

      if(aiFormData.enable_rizwan_event!=='yes' && aiFormData.enable_face_detection==='yes' && action && isInRangeRef.current){    

        setTimeout(() => {
          onAvatarSpeakingDone();
        }, durationInMilliseconds);

      }
  
      
      
  
      
      return data.data;
    } catch (error) {
      console.error('Request failed:', error);
      throw error;
    }
  }
  
  
   // Function that will be called after the avatar finishes speaking
   const onAvatarSpeakingDone = () => {  
  
    if(aiFormData.enable_rizwan_event==='yes' && (rizwanScriptStepRef.current===3 || rizwanScriptStepRef.current===4)){ 
        
      console.log("Avatar finished speaking, perform actions here.");
      // showSuccessToast('Please Speak Now!');
        setStartSpeech(true);    
    }


     if(aiFormData.enable_rizwan_event!=='yes' && aiFormData.enable_face_detection==='yes' && isInRangeRef.current){    

      setFaceDetectionStartSpeech(true);

    }
   
  };
  
  
  const translateText=async(text, targetLanguage)=>{
    const response = await fetch(`https://api.mymemory.translated.net/get?q=${encodeURIComponent(text)}&langpair=en|${targetLanguage}`);
    const data = await response.json();
    if (data.responseStatus !== 200) {
        throw new Error(data.responseDetails);
    }
    return data.responseData.translatedText;
  }
  
  
  
  
  
  
  const startConverting = (ses) => {  
    console.log("start talk",ses);
   if(aiFormData.enable_rizwan_event!=='yes' && aiFormData.enable_face_detection!=='yes'){
      document.getElementById("ai_starttalk").style.display = 'none';
      document.getElementById("stoprecording").style.display = 'block';
    }
  
  };
  
   useEffect(() => {
      // Assign the function to the ref
      onspeechTextRef.current = (text) => {
        setSpeechText(text);
      };
    }, []);
  
  // Stop recording
  const stopRecording = async (liveStreamText,languageCode) => {
   
    if (!aiFormData) {
      console.error('translationData is not available');
      return;
    }
  
  
  
  //   SpeechRecognition.stopListening();
  //   setRecognitionRunning(false);
    console.log("stop talk");
  
    if(aiFormData.enable_rizwan_event!=='yes' && aiFormData.enable_face_detection!=='yes'){
      document.getElementById("ai_starttalk").style.display = 'block';
      document.getElementById("stoprecording").style.display = 'none';
    }
  
  
  
    console.log("transcript",liveStreamText)
  
    const transaltionSpeechLive=UseTranslationData(aiFormData, languageCode);

    if(aiFormData.enable_rizwan_event!=='yes'){
       setTranslationData(transaltionSpeechLive);

    } 
  
    let modifiedText = transaltionSpeechLive?.modify_response_text || '';
    // let prompt = aiFormData?.enable_rizwan_event ?
    //  'સરસ, વહેલા આવવા બદલ આભાર! મેં એમીને જણાવી દીધું છે કે તમે અહીં છો, તે હમણાં જ બીજા ક્લાયન્ટ સાથે કામ કરી રહી છે. બેસો અને તે ટૂંક સમયમાં બહાર આવશે. શું હું તમને મદદ કરી શકું એવું બીજું કંઈ છે?':
     
    //  `${modifiedText} ${liveStreamText}`.trim();
  
    let prompt = `${modifiedText} ${liveStreamText}`.trim();
  
    let flag = false;


    if (aiFormData.enable_rizwan_event==='yes' && numberRef.current.includes(rizwanScriptStep)) {

      console.error('Sorry this step already exist');
      return;


    }
  
  
    if(aiFormData.enable_rizwan_event==='yes' && rizwanScriptStep===2){ 
        prompt = "Svāgata chē!,, Mēṁ ēmīnē jaṇāvī dīdhuṁ chē.,, Śuṁ huṁ tamanē bījī kō'ī bābatamāṁ madada karī śakuṁ?";
        flag =true;
        rizwanScriptStepEnglishRef.current=null;
    }
  
    if(aiFormData.enable_rizwan_event==='yes' && rizwanScriptStep===3){ 
      prompt = "Oh, that's fantastic, did you know your advisor Amy, is also an avid traveler! One of our associates, directly behind me and to your right, would be happy to help you get up to 1000 Euros today. The current wait time is less than 5 minutes.  Anything else you need assistance with?";
      flag =true;
      rizwanScriptStepEnglishRef.current=null;
    }
  
    if(aiFormData.enable_rizwan_event==='yes' && rizwanScriptStep===4){
  
      prompt = "Happy to help,  Amy will be with you shortly, Have a great rest of your evening.";
      flag =true;
      rizwanScriptStepEnglishRef.current=null;
  
    }
  
     
  
    if (liveStreamText) {
      console.log("transcriptd",liveStreamText);


       let cases = aiFormData.enable_rizwan_event==='yes' ? 'Rizwan Event' : 'Non Rizwan Event';

      let logId= await recordLog(prompt,liveStreamText,'pending',cases);

      console.log("logId",logId)



     
  
      try {

        if(aiFormData.enable_rizwan_event!=='yes'){

        let beforeResponseText = transaltionSpeechLive.before_response_text || '';

        console.log("beforeResponseText",beforeResponseText)


        const predefinedText = getRandomMessage(beforeResponseText);


        console.log("predefinedText",predefinedText)

  
        await repeat(sessionInfo.session_id, predefinedText);

       

        let text;
  
         // Call talkToOpenAI to get the actual response
         if(aiFormData?.enable_python_script==='yes'){
          text = await runPythonScript(prompt,logId);
         }else{
          text = await talkToOpenAI(prompt,logId);
         }
          
  
         if (text) {
           
           await repeat(sessionInfo.session_id, text,true);          
          
         } else {
           console.error('Failed to get response from AI');
         }
  
        }else{
          numberRef.current.push(rizwanScriptStepRef.current);
          await repeat(sessionInfo.session_id, prompt);  

          updateLog(liveStreamText,'success','Rizwan Event',prompt,logId);
  
       
          if(flag){
            setRizwanScriptStep(prevStep => prevStep + 1);
            
        
          }
        }
  
       
  
      } catch (error) {
        console.error('Error talking to AI:', error);
      }
    }
  
    //resetInactivityTimer(); // Reset inactivity timer on stop recording
  } 
  
  
  const getRandomMessage = (inputString) => {
    const messages = inputString.split('|');
    const trimmedMessages = messages.map(message => message.trim());
    const randomIndex = Math.floor(Math.random() * trimmedMessages.length);
    return trimmedMessages[randomIndex];
  };
  
  
  const talkToOpenAI=async(prompt,logId)=>{
    const userId=aiFormData?.user;
    let cases = aiFormData.enable_rizwan_event==='yes' ? 'Rizwan Event' : 'Non Rizwan Event';
    const response = await fetch(`/openai/complete`, {
      
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ prompt,userId }),
    });
    if (response.status === 500) {
      console.error('Server error');
      updateLog(prompt,'failed',cases,'error',logId,'error');
     
    } else {
      const data = await response.json();

      updateLog(prompt,'success',cases,data.text,logId);
      return data.text;
    }
  }


  const recordLog=async(prompt,userInput,status,casee,aiResponse=null,error=null)=>{
    const userId=aiFormData?.user;
    const response = await fetch(`/save-log`, {
      
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({userId,client_url,userInput, prompt, aiResponse, status, error, casee }),
    });
    if (response.status === 500) {
      console.error('Server error');
     
    } else {
      const data = await response.json();
      return data.logId;
    }
  }


  const updateLog=async(prompt,status,casee,aiResponse,logId,error=null)=>{
    const userId=aiFormData?.user;
    const response = await fetch(`/update-log`, {
      
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({userId,client_url, prompt, aiResponse, status, error, casee,logId }),
    });
    if (response.status === 500) {
      console.error('Server error');
     
    } else {
      const data = await response.json();
      return data.logId;
    }
  }
  
  
  useEffect(()=>{
      console.log("sessionInfo",avtarsesssion);
  
      if(avtarsesssion){
        setSessionId(avtarsesssion.session_id)
      }
  
  },[avtarsesssion])
  
    
  
  
    const playPromoMessage=async(sessionID) => {
     // alert("dsfdsf")
      const rizwanStatus = aiFormData.enable_rizwan_event==='yes' ? true : false;

      // if(aiFormData.enable_face_detection==='yes' && !isInRangeRef.current){

      //   const disconnectavtar = document.getElementById("disconnectavtar");
      //   if (disconnectavtar) {
      //     disconnectavtar.click();
      //   }   

      //   return
      // }
  
      if(translationData && !rizwanStatus){
       
  
      let promoMessages=translationData.promo_message;
        const promoMessage = getRandomMessage(promoMessages);
  
        console.log("promoMessage", promoMessage);
  
        await repeat(sessionID, promoMessage);
      }
        // Implement your avatar speaking the promo message here
        // Example: await repeat(sessionInfo.session_id, promoMessage);
    };


    const resetSteps=()=>{
      setRizwanScriptStep(1);
      numberRef.current = [];  // Reset to an empty array or 0 if it's a single number ref
      showSuccessToast('Rizwan Script Steps Reset Successfully!');

    }


    const runPythonScript = async (query,logId) => {
    
      try {
        let cases = aiFormData.enable_rizwan_event==='yes' ? 'Rizwan Event' : 'Non Rizwan Event';
        const response = await fetch(`https://py.holomedia.ai/chat`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',          
          },
          body: JSON.stringify({ query }),
        });
  
        if (!response.ok) {
          console.error('Server error');
          updateLog(query,'failed',cases,'Server error',logId,'error');
          throw new Error('Server error');
        }

        
  
        const data = await response.json();
        if(data.success && data.text){
          updateLog(query,'success',cases,data.text,logId);     

        }else{
          updateLog(query,'failed',cases,data.text,logId,data.error);  
        }

        return data.text ? data.text : "Sorry no response found from python script please try again.";
        
        
  
      } catch (error) {
        console.error('Error in startSession:', error);
      }
    };


     useEffect(() => {
      const interval = setInterval(() => {
        console.log("Child isInRange:", isInRangeRef.current); // Logs the current value from the child
      }, 1000);
  
      return () => clearInterval(interval); // Cleanup on unmount
    }, []);


     const playUserSilenceMsg=async(sessionID) => {
      const rizwanStatus = aiFormData.enable_rizwan_event==='yes' ? true : false;
  
      if(translationData && !rizwanStatus){
       
  
      let promoMessages=translationData?.user_not_speak_text || 'How can I help you please speak Anything';
        const promoMessage = getRandomMessage(promoMessages);
  
        console.log("promoMessage user silence", promoMessage);
  
        await repeat(sessionID, promoMessage,true);
      }
        // Implement your avatar speaking the promo message here
        // Example: await repeat(sessionInfo.session_id, promoMessage);
    };


    const playRandomSpeech=async(sessionID) => {
      const rizwanStatus = aiFormData.enable_rizwan_event==='yes' ? true : false;
  
      if(translationData && !rizwanStatus){
       
  
      let promoMessages= translationData?.user_mic_random_message || 'Sorry! I cant hear you can please speak again';
        const promoMessage = getRandomMessage(promoMessages);
  
        console.log("playRandomSpeech", promoMessage);
  
        await repeat(sessionID, promoMessage,true);
      }
      
    };
  

    const disconnectSession = async () => {
      try {
        if (peerConnection) {
          // Close the peer connection to disconnect the session
          peerConnection.close();
          setPeerConnection({});
          console.log('RTCPeerConnection closed.');
        }
    
        // Clear the session information
        // setSessionInfo(null);
    
        // // Hide the avatar display if necessary
        // setIsAvatarVisible(false);
        document.body.classList.remove('hide');
    
        // Clear the media stream from the video element
      
        setIdealMode(true);
        scheduleFollowUp();

        await clearHistory();
    
        console.log('Avatar streaming session disconnected.');
      } catch (error) {
        console.error('Error disconnecting session:', error);
      }
    };


    const clearHistory = async () => {
  try {
    const response = await fetch("https://py.holomedia.ai/clear_history", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data; // Expecting a response message from the server
  } catch (error) {
    console.error("Error clearing history:", error);
    return { error: true, message: error.message };
  }
};


  // Function to run after a delay
  const followUpAction = () => {
    console.log("Follow-up action executed after delay.");
    window.location.reload();
    // Add your desired follow-up logic here
  };

  // Function to schedule the follow-up action
  const scheduleFollowUp = () => {
   
    setLoopSessionData(client_url,{ idealMode:true})

   
    const delay = aiFormData?.loop_reset_time * 60 * 1000; // Delay in milliseconds (5 minutes)
    console.log(`Follow-up action scheduled in ${delay / 1000} seconds.`);
    
    timeoutIdRef.current = setTimeout(() => {
      followUpAction();
      
    }, delay);


    const camerarest = 2 * 60 * 1000;


    resetCameraRef.current = setInterval(() => {
      setKey((prevKey) => prevKey + 1); // Increment the key to force re-render
    }, camerarest); // Update every 5 seconds (adjust as needed)
  };

  // Function to cancel the scheduled follow-up
  const cancelFollowUp = () => {
    
     
      clearTimeout(timeoutIdRef.current); // Cancel the timeout
      timeoutIdRef.current = null;
      unsetLoopSessionData(client_url);

      clearTimeout(resetCameraRef.current);
      resetCameraRef.current = null;
      
      console.log("Follow-up action cancelled.");
    
  };

    


     const handleLoadedMetadataloop = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = startTime;
    }
  };

  const handleEnded = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = startTime;
      videoRef.current.play(); // Restart the video from the specified time
    }
  };

    const resetPage = () => {
  window.location.href = window.location.pathname + '?reload=' + new Date().getTime();
      };


      
    // Function to get current day in EST
const getCurrentDayInEST = () => {
  const timeZone = 'America/New_York'; // EST/EDT Time Zone
  
  // Get the current UTC time
  const currentUtcDate = new Date();
  
  // Convert UTC time to EST/EDT time
  const zonedDate = toZonedTime(currentUtcDate, timeZone);
  
  // Format to get the day name (e.g., "Monday", "Tuesday")
  return format(zonedDate, 'EEEE', { timeZone }); // 'EEEE' returns full weekday name
};

// Function to check if current time is in the range
const isCurrentTimeInRange = (startTime, endTime) => {
  const timeZone = 'America/New_York'; // EST/EDT Time Zone

  // Get the current UTC time and convert it to EST
  const currentUtcDate = new Date();
  const currentZonedDate = toZonedTime(currentUtcDate, timeZone);

  // Parse the startTime and endTime as EST time
  const startTimeInEST = parse(startTime, 'HH:mm', currentZonedDate, { timeZone });
  const endTimeInEST = parse(endTime, 'HH:mm', currentZonedDate, { timeZone });

  // Check if the current time is within the range
  return currentZonedDate >= startTimeInEST && currentZonedDate <= endTimeInEST;
};

// Function to filter weekly timing and check if current time is in range
const filterCurrentDayAndCheckTime = (weeklyTiming) => {

 // console.log("weeklyTiming",weeklyTiming)
  const currentDay = getCurrentDayInEST();
  
  // Find the timing for the current day
  const currentDayTiming = weeklyTiming?.find(item => item?.day === currentDay && item?.isOpen);
  
  if (!currentDayTiming) return null; // If no open timing for today, return null
  
  const { startTime, endTime } = currentDayTiming;
  
  // Check if current time is within the range of start and end times
  const isInRange = isCurrentTimeInRange(startTime, endTime);
  
  return isInRange;
};
  

const resetLoopVideoBtnCase=async()=>{ 

  if(idealMode){
    cancelFollowUp();

    setWaitingTime(!waitingTime);

   const ai_concierge_generate = document.getElementById("ai_concierge_generate");
   if (ai_concierge_generate) {
    ai_concierge_generate.click();
   }



    setSessionInfo({});


   return;

  }
}



const setLoopSessionData = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value)); // Convert to JSON for non-string values
};

const getLoopSessionData = (key) => {
  const data = sessionStorage.getItem(key);
  return data ? JSON.parse(data) : null; // Parse JSON if it exists
};

const unsetLoopSessionData = (key) => {
 
  sessionStorage.removeItem(key);
};
  
    
  
    if (!isAvatarVisible && !aiFormData) {
      return ( <><div>Loading...</div></>
       ) ;
    }else{
  
    return (
      <div className="main">       
  
        <div className="videoSectionWrap" id="sec1" style={{display: idealMode ? 'none' : ''}}>       

        {/* <video width="750" height="500" controls >
      <source src={video} type="video/mp4"/>
     </video> */}
        {/* <video src={video} id="mediaElement"  className="videoEle show" autoPlay muted /> */}
  
          {!isTimeRange && (

            <MainImageLogo />     


          )}      
  
          <div className="ai_concierge">
            <button type="button" 
            className="ai_concierge"
             id="ai_concierge_generate" 
             
             style={{ backgroundColor: siteBrandColor,visibility:'hidden' }}
             onClick={() => createNewSession()}>
              {translationData?.home_screen_btn_text1}</button>
          </div>
  
          {handleLiveChatVisibility(aiFormData, translationData)}
  
  
          <div className="language_sec" id="language_sec" style={{ display: 'none' }}>
          {renderLanguageButtons()}
          </div>
        </div>
  
        <p id="status" style={{ display: 'none' }} />

        <button id="disconnectavtar" style={{display:'none'}} onClick={disconnectSession}>Disconnect Avatar</button>

  
        <div 
        className="videoSectionWrap avtarsection"         
        style={{ display: isAvatarVisible ? 'flex' : 'none' }} // Set the display dynamically
        >
          
  
              {sessionInfo && (
                  
                  <div className="videoWrap">
                  <HeaderLogo styles={positionAvatarLogo(aiFormData?.avtar_page_image_location)} onClick={resetPage} />

                  {idealMode &&  <video
                                  
                                  src={aiFormData?.looping_video_url}
                                  id="mediaElement"
                                  className="videoEle show"
                                  // controls
                                  autoPlay
                                  muted
                                  loop
                                 
                                />
                              }

                  {!idealMode && <video  id="mediaElement" ref={mediaRef} className="videoEle show" autoPlay muted />}
                    <div className="imagebuttons">      

                      {isTimeRange &&  aiFormData?.speech_tool==='google' && (

                       

                          <AudioToText 
                          siteBrandColor={siteBrandColor} 
                          onstartConverting={startConverting}
                          onstopRecording={stopRecording}
                          translationData={translationData}
                          aiFormData={aiFormData}     
                          onplayPromoMessage={playPromoMessage}                  
                          onsessionInfo={sessionInfo?.session_id}
                          // onSpeechRecognitionEnd={(text) => console.log("Final text:", text)}
                          onstartspeech={startSpeech}
                          rizwanScriptStep={rizwanScriptStep}
                          setonstartspeech={()=>  setStartSpeech(!startSpeech)}
                          rizwanScriptStepEnglishRef={rizwanScriptStepEnglishRef.current}
                          rizwanButtonFun={welcomemsg}
                          onfaceDetectionStartSpeech={faceDetectionStartSpeech}
                          setfaceDetectionStartSpeech={()=>setFaceDetectionStartSpeech(!faceDetectionStartSpeech)}
                          isInRangeRefFun={isInRangeRef.current}
                          onplayUserSilenceMsg={playUserSilenceMsg}
                          playRandomSpeech={playRandomSpeech} 
                          idealMode = {idealMode}   
                          resetLoopVideoBtnCase = {resetLoopVideoBtnCase}
                          waitingTime = {waitingTime}
                          />

                      )} 

                      {isTimeRange &&  aiFormData?.speech_tool==='javascript' && (

                       

                          <AudioToTextJavaScript 
                          siteBrandColor={siteBrandColor} 
                          onstartConverting={startConverting}
                          onstopRecording={stopRecording}
                          translationData={translationData}
                          aiFormData={aiFormData}     
                          onplayPromoMessage={playPromoMessage}                  
                          onsessionInfo={sessionInfo?.session_id}
                          // onSpeechRecognitionEnd={(text) => console.log("Final text:", text)}
                          onstartspeech={startSpeech}
                          rizwanScriptStep={rizwanScriptStep}
                          setonstartspeech={()=>  setStartSpeech(!startSpeech)}
                          rizwanScriptStepEnglishRef={rizwanScriptStepEnglishRef.current}
                          rizwanButtonFun={welcomemsg}
                          onfaceDetectionStartSpeech={faceDetectionStartSpeech}
                          setfaceDetectionStartSpeech={()=>setFaceDetectionStartSpeech(!faceDetectionStartSpeech)}
                          isInRangeRefFun={isInRangeRef.current}
                          onplayUserSilenceMsg={playUserSilenceMsg}
                          playRandomSpeech={playRandomSpeech} 
                          idealMode = {idealMode}   
                          resetLoopVideoBtnCase = {resetLoopVideoBtnCase}
                          waitingTime = {waitingTime}
                          />

                      )} 
                       
                      
                      {aiFormData?.enable_face_detection==='yes' && isTimeRange && (
                        <FaceDetection
                        key={key} // Force re-render when the key changes
                        onplayPromoMessage={welcomemsg}                  
                        onsessionInfo={sessionInfo?.session_id}
                        onstartspeech={()=>{
                          setStartSpeech(!startSpeech); 
                      
                        } }
                        styles={positionAvatarLogo(aiFormData?.face_detection_location)}
                        aiFormData={aiFormData}    
                        rizwanScriptStep={rizwanScriptStep}
                        isInRangeRef={isInRangeRef}
                        
                        />
                        )}
    
    
                        
                        
                    </div>
                  </div>
                  
              ) 
              }         
  
         
        </div>

        {aiFormData.enable_rizwan_event==='yes' && showButton && (
            <div id="back_to_menu">
              
              <button type="button" style={{ fontSize: '42px' }} onClick={()=>sessionInfo?.session_id && isAvatarVisible ? reloadPage() : createNewSession()}>
              <i className={`fa fa-refresh ${ loaderStart && !isAvatarVisible ? 'fa-spin' : ''}`} />
          </button>
          </div>

          )}

        {isAvatarVisible && (

        <div className="copyright_section" style={{cursor:"pointer"}} onClick={reloadPage}>
          <span id="footer_text_copyright" style={{ backgroundColor: translationData?.welcom_text_back_color, color: translationData?.welcom_text_color }}> {translationData?.footer_text}</span>
        </div>

        )}
      </div>
    );
            }
}
