import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import axios from 'axios';


export default function ViewClientLogs() {
    const [clients, setClients] = useState([]);
    const [search, setSearch] = useState('');
    const [filteredClients, setFilteredClients] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    useEffect(() => {
        fetch('/client-logs')
            .then(response => response.json())
            .then(resp => {
                const filteredClients = resp?.users;
                setClients(filteredClients);
                setFilteredClients(filteredClients);
            })
            .catch(error => console.error('Error fetching client data:', error));
    }, []);

    useEffect(() => {
        const result = clients.filter(client => {
            const clientTimestamp = new Date(client.timestamp);
            const isWithinDateRange = (!startDate || clientTimestamp >= new Date(startDate)) &&
                                      (!endDate || clientTimestamp <= new Date(endDate));
            // const matchesSearch = client.spokenText.toLowerCase().includes(search.toLowerCase()) ||
            //                       client.aiResponse.toLowerCase().includes(search.toLowerCase()) ||
            //                       client.status.toLowerCase().includes(search.toLowerCase()) ||
            //                       client.case.toLowerCase().includes(search.toLowerCase()) ||
            //                       client.client_url.toLowerCase().includes(search.toLowerCase());

            const matchesSearch = client.client_url.toLowerCase().includes(search.toLowerCase());
                                  
            return isWithinDateRange && matchesSearch;
        });
        setFilteredClients(result);
    }, [search, clients, startDate, endDate]);

    const deleteClientLog = async (id) => {

        const confirmDelete = window.confirm('Are you sure you want to delete this client log?');
        if (!confirmDelete) {
          return; // Exit if user cancels
        }
        try {
          await axios.delete(`/deleteclientlog/${id}`);
          const filteredClients = clients.filter((user) => user._id !== id); // Update local state
           setClients(filteredClients);
            setFilteredClients(filteredClients)
          
        } catch (error) {
          console.error('Error deleting user:', error);
          alert('Failed to delete user');
        }
      };

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        };
        return date.toLocaleString('en-US', options).replace(',', '');
    };

    const columns = [
        {
            name: 'Date / Time',
            selector: row => formatTimestamp(row.timestamp),
            sortable: true,
            width: '200px',
        },
          {
            name: 'User Input',
            selector: row => (
              <div style={{ maxWidth: '250px', maxHeight: '200px', overflow: 'auto', whiteSpace: 'pre-wrap' }}>
                {row.userInput ? row.userInput : ''}
              </div>
            ),
            sortable: true,
        },
        {
            name: 'LLM input',
            selector: row => (
              <div style={{ maxWidth: '250px', maxHeight: '200px', overflow: 'auto', whiteSpace: 'pre-wrap' }}>
                {row.spokenText}
              </div>
            ),
            sortable: true,
        },
        {
            name: 'Response',
            selector: row => (
              <div style={{ maxWidth: '250px', maxHeight: '200px', overflow: 'auto', whiteSpace: 'pre-wrap' }}>
                {row.aiResponse}
              </div>
            ),
            sortable: true,
        },
        {
            name: 'Client URL',
            cell: row => (
              <Link to={`/${row.client_url}`} target='_blank' style={{ 
                textDecoration: 'underline', 
                color: 'blue',
                padding: '10px',
                borderRadius: '8px'
              }}>{row.client_url}</Link>
            ),
            sortable: true,
            width: '200px',
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            width: '150px',
        },
        
        
        {
            name: 'Error Log',
            selector: row => (
              <div style={{ maxWidth: '250px', maxHeight: '200px', overflow: 'auto', whiteSpace: 'pre-wrap' }}>
                {row.error ? row.error : '----'}
              </div>
            ),
            sortable: true,
        },
        {
            name: 'Action',
            cell: row => (
              <div className="dropdown">
                <a className="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle" href="#" role="button" data-toggle="dropdown">
                  <i className="dw dw-more"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                  <a className="dropdown-item" href={`/add-client/${row?.user}`}><i className="dw dw-eye"></i> View Profile</a>
                  <a className="dropdown-item" href={`/view-settings/${row?.user}`}><i className="dw dw-edit2"></i> View Setting</a>
                  <button className="dropdown-item" onClick={() => deleteClientLog(row?._id)}><i className="dw dw-delete-3"></i> Delete</button>
                </div>
              </div>
            ),
        },
    ];

    const customStyles = {
        rows: {
            style: {
                marginBottom: '10px',
                padding: '10px 0',
                borderBottom: '1px solid #ccc',
            },
        },
        tableWrapper: {
            style: {
                overflowX: 'auto',
            },
        },
    };

    return (
        <div className="main-container">
            <div className="pd-ltr-20 xs-pd-20-10">
                <div className="min-height-200px">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <div className="title">
                                    <h4>View Clients Logs</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-box mb-30">
                        <div className="pd-20">
                        </div>
                        <div className="pb-20">
                            {/* Add a search input */}
                            <input
                                  type="text"
                                  placeholder="Search Clients"
                                  value={search}
                                  onChange={e => setSearch(e.target.value)}
                                  style={{ marginBottom: '20px', padding: '5px', width: '30%', float: "right" }}
                                  className='form-control'
                              />

                              
                              
                              {/* Add date range inputs */}
                              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                  <input
                                      type="date"
                                      value={startDate}
                                      onChange={e => setStartDate(e.target.value)}
                                      className='form-control'
                                      style={{ width: '150px', marginRight: '10px' }} // Adjusted width and margin for better spacing
                                  />
                                  <span style={{ marginRight: '10px' }}>to</span> {/* Label for clarity */}
                                  <input
                                      type="date"
                                      value={endDate}
                                      onChange={e => setEndDate(e.target.value)}
                                      className='form-control'
                                      style={{ width: '150px' }} // Adjusted width
                                  />
                              </div>

                            <DataTable
                                columns={columns}
                                data={filteredClients} // Use filtered data
                                pagination
                                paginationRowsPerPageOptions={[50,100,150,200,250,300]}
                                paginationPerPage={50} // Set default records per page to 25
                                highlightOnHover
                                customStyles={customStyles}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
