import React, { useEffect, useState, useRef } from "react";
import * as io from "socket.io-client";
import { useDispatch,useSelector } from 'react-redux';
import UseToast from "../UseToast";
import UseTranslationData from "../UseTranslationData";

const sampleRate = 16000;

const getMediaStream = () =>
  navigator.mediaDevices.getUserMedia({
    audio: {
      deviceId: "default",
      sampleRate: sampleRate,
      sampleSize: 16,
      channelCount: 1,
    },
    video: false,
  });

const AudioToText = ({
  siteBrandColor,
  onstartConverting,
  onstopRecording,
  translationData,
  aiFormData,
  onplayPromoMessage,  
  onsessionInfo,
  // onSpeechRecognitionEnd,
  onstartspeech,
  rizwanScriptStep,
  setonstartspeech,
  rizwanScriptStepEnglishRef,
  rizwanButtonFun,
  onfaceDetectionStartSpeech,
  setfaceDetectionStartSpeech,
  isInRangeRefFun,
  onplayUserSilenceMsg,
  playRandomSpeech,
  idealMode,
  resetLoopVideoBtnCase,
  waitingTime
}) => {


  const [connection, setConnection] = useState(null);
  const [currentRecognition, setCurrentRecognition] = useState("");
  const [currentRecognitionLang, setCurrentRecognitionLang] = useState("");
  const [userSilent,setUserSilent]=useState(false);
  const [audioPaused,setAudioPaused] = useState(false);

  const [recognitionHistory, setRecognitionHistory] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const processorRef = useRef(null);
  const audioContextRef = useRef(null);
  const audioInputRef = useRef(null);
  const recordingTimeoutRef = useRef(null); // Timer for auto-stop after 10 seconds
  const inactivityTimer = useRef(null); // Timer for inactivity
  const silenceTimer = useRef(null); // Timer for silence
  const onsessionInfoRef = useRef(onsessionInfo);
  const rizwanScriptStepRef= useRef(rizwanScriptStep);

  const detectIsFinal= useRef(false);

  const isUserSilent= useRef(false);

  const isAudioPaused = useRef(false);

  


  const inactivityTimeLimit = aiFormData?.promo_time_limit; // Default to 10 seconds
  const blankSpaceTime = aiFormData?.blank_space; // Default to 5 seconds

  const currentRecognitionRef = useRef("");
  const currentRecognitionLangRef = useRef("");
  const currentRecognitionLangListenRef = useRef('');

  const { showSuccessToast, showWarningToast } = UseToast(); // Use the custom hook

  const [audioDataFinal,setAudioDataFinal]=useState({});

  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentText, setCurrentText] = useState("");
  const words = "Welcome! I've let Amy know you are here. Is there anything else I can help you with?".split(" ");
  const timerRef = useRef(null); 



  useEffect(()=>{

    isUserSilent.current = userSilent;

  },[userSilent])


  useEffect(() => {
    if (rizwanScriptStepEnglishRef) {
      setCurrentWordIndex(0); // Reset word index when it becomes true
      timerRef.current = setInterval(() => {
        setCurrentWordIndex((prevIndex) => prevIndex + 1);
      }, 380); // Adjust the speed here (500ms = 0.5 second per word)
    } else {
      clearInterval(timerRef.current);
    }

    return () => {
      clearInterval(timerRef.current); // Clean up on unmount
    };
  }, [rizwanScriptStepEnglishRef]);

  useEffect(() => {
    setCurrentText(words.slice(0, currentWordIndex).join(" "));
  }, [currentWordIndex]);



useEffect(()=>{
  rizwanScriptStepRef.current=rizwanScriptStep;
},[rizwanScriptStep])

useEffect(()=>{

  detectIsFinal.current = audioDataFinal?.isFinal ? true: false

},[audioDataFinal])
  

useEffect(() => {

  currentRecognitionRef.current = currentRecognition;
  
}, [currentRecognition]);

useEffect(() => {
  currentRecognitionLangRef.current = currentRecognitionLang;

  const listenWord=UseTranslationData(aiFormData,currentRecognitionLang.split('-')[0]);
  currentRecognitionLangListenRef.current = listenWord?.listening_text;
}, [currentRecognitionLang]);

  useEffect(() => {
    onsessionInfoRef.current = onsessionInfo;
  }, [onsessionInfo]);

  useEffect(()=>{
    if(onstartspeech){ 
      playRizwanEvent();
  
    }
  },[onstartspeech])


  useEffect(()=>{

    isAudioPaused.current = audioPaused;

   
  },[audioPaused])


  useEffect(()=>{

   // console.log("isInRangeRefFun",isInRangeRefFun)

    if(onfaceDetectionStartSpeech && isInRangeRefFun){

      setAudioPaused(false);

        const ai_starttalk = document.getElementById("ai_starttalk");
        if (ai_starttalk) {
            ai_starttalk.click();
        }

      //connect();
      setUserSilent(false);  

    }

  },[onfaceDetectionStartSpeech])


  const playRizwanEvent=()=>{

      if(aiFormData.enable_rizwan_event==='yes' && aiFormData?.enable_rizwan_manual_script === 'yes'  &&  rizwanScriptStepRef.current === 2){

      showSuccessToast('Please Speak Now!');

      setTimeout(() => {
        //console.log("Triggering runManualRizwan...");
        runManualRizwan();
      }, 5000); 

    }else{

      disconnect();      
      //connect();

      const ai_starttalk = document.getElementById("ai_starttalk");
        if (ai_starttalk) {
            ai_starttalk.click();
        }

    }
  }

   const runManualRizwan=()=>{

   

    console.log("Silence detected. Stopping the recording...");
    const finalRecognition = "adasdasdasdsadas"; // Accessing the current recognition value from the ref

    const finalRecognitionLang = currentRecognitionLangRef.current;

    console.log("finalRecognitionLang", finalRecognitionLang); // This should now log the correct value
  
  
    if(finalRecognition ){

     
      onstopRecording(finalRecognition,'en');   

        currentRecognitionRef.current = "";
        setCurrentRecognition('');
        setonstartspeech();

      
     

    }



  }


  const playPromoMessage = () => {

    disconnect(); // Stop the recording
    onplayPromoMessage(onsessionInfoRef.current);
    resetInactivityTimer();
  };

  const resetInactivityTimer = () => {
    if (inactivityTimer.current) {
      clearTimeout(inactivityTimer.current);
    }
    inactivityTimer.current = setTimeout(
      playPromoMessage,
      inactivityTimeLimit * 60* 1000 // Convert to milliseconds
    );
  };

  const resetSilenceTimer = () => {    

    if(aiFormData.enable_rizwan_event!=='yes'){

     

      recordingTimeoutRef.current = setTimeout(() => {
              playUserSilenceMessage();
            }, aiFormData.speech_time_limit*1000); 
     

    }
  };


  const playUserSilenceMessage=()=>{  


     if(aiFormData.enable_rizwan_event!=='yes' && !isUserSilent.current){

          onplayUserSilenceMsg(onsessionInfoRef.current);

          if(aiFormData.enable_face_detection==='yes'){

            setfaceDetectionStartSpeech();

          }          
        
      }



       // Manually trigger the button click
        const stopRecordingButton = document.getElementById("stoprecording");
        if (stopRecordingButton) {
            stopRecordingButton.click();
        }

  }

  const stopRecordingDueToSilence = () => {
    console.log("Silence detected. Stopping the recording...");
    const finalRecognition = currentRecognitionRef.current; // Accessing the current recognition value from the ref

    const finalRecognitionLang = currentRecognitionLangRef.current;

    console.log("finalRecognitionLang", finalRecognitionLang); // This should now log the correct value
  


    if(finalRecognition ){

     
      onstopRecording(finalRecognition,finalRecognitionLang.split('-')[0]);
      if(aiFormData.enable_rizwan_event==='yes'){

        currentRecognitionRef.current = "";
        setCurrentRecognition('');
        setonstartspeech();

      }


       if(aiFormData.enable_face_detection==='yes' && aiFormData.enable_rizwan_event!=='yes'){

        setfaceDetectionStartSpeech();
      

      }

       setAudioPaused(true);

       // Manually trigger the button click
        const stopRecordingButton = document.getElementById("stoprecording");
        if (stopRecordingButton) {
            stopRecordingButton.click();
        }
      
      //setCurrentRecognition("");

      

     // disconnect();

    }
    
  };


  const stringConversion = (searchText) => {
    // Get individual replacements from translationData
    const textArray = translationData?.text_replace.split('\n').filter(item => item.trim() !== '');
  
    // Split the search text into words, using regular expressions to account for line breaks or special characters
    const wordsToSearch = searchText.split(/\s+/);
  
    console.log("Original wordsToSearch", wordsToSearch);
  
    // Function to normalize words by removing special characters and converting to lowercase
    const normalizeWord = (word) => word.replace(/[^\w\s]/gi, '').toLowerCase();
  
    // Update each word in the search text if it matches any translationData replacements
    const updatedText = wordsToSearch.map(word => {
      const normalizedWord = normalizeWord(word);
      
      // Iterate through textArray to find matching replacements
      for (let item of textArray) {
        const [lhs, rhs] = item.split('|').map(part => part.trim());
        const normalizedLHS = normalizeWord(lhs);
  
        // If a normalized word matches normalized LHS, replace it with RHS
        if (normalizedLHS === normalizedWord) {
          return rhs; // Replace matched word
        }
      }
      return word; // Return unchanged word if no match found
    }).join(' ');
  
    console.log("Updated Text", updatedText);
    return updatedText;
  };


  const speechRecognized = (data) => {    

    if (data.isFinal) {
      
      let uptext =stringConversion(data.text);
      setCurrentRecognition(uptext);
      currentRecognitionRef.current = uptext;
      setRecognitionHistory((old) => [data.text, ...old]);
      setCurrentRecognitionLang(data.languageCode[0]);
         

      const totalTextWordCount = uptext.split(/\s+/).length;

      if(totalTextWordCount <=2){       

        playRandomSpeech(onsessionInfoRef.current);

        if(aiFormData.enable_face_detection==='yes'){

            setfaceDetectionStartSpeech();

          }  

       // Manually trigger the button click
        const stopRecordingButton = document.getElementById("stoprecording");
        if (stopRecordingButton) {
            stopRecordingButton.click();
        }



      }else{
        stopRecordingDueToSilence();
      }
    

     
      
     
    } else {
      //setCurrentRecognition(uptext);

      setCurrentRecognitionLang(data.languageCode[0]);
      //detectIsFinal.current=false;
     // setAudioDataFinal({});
      
       
      

    }

    // if (recordingTimeoutRef.current) {
    //     clearTimeout(recordingTimeoutRef.current);
    //     }
    resetInactivityTimer(); // Reset inactivity timer whenever speech is detected
    if (recordingTimeoutRef.current) {
      clearTimeout(recordingTimeoutRef.current);
      
  }

  
  };

  // On Click start

  const connect = () => {
    if(aiFormData?.enable_face_detection!=='yes' && idealMode){

      resetLoopVideoBtnCase();

      return;

    }

    if (connection) connection.disconnect();
    //const socket = io.connect("http://localhost:5000"); // Replace with your server's IP address

   const socket = io.connect("https://aih.holomedia.ai");

    
    socket.on("connect", () => {
      console.log("connected", socket.id);
      currentRecognitionLangListenRef.current='';
      if(aiFormData.enable_rizwan_event==='yes'){
        showSuccessToast('Please Speak Now!');

      }


        if(aiFormData.enable_rizwan_event!=='yes' && aiFormData.enable_face_detection==='yes'){

        showSuccessToast('Please Speak Now!');



      }
      
      setConnection(socket);
      onstartConverting(onsessionInfo)

      // Send session info or any other data
    const data = { 
      sessionInfo: onsessionInfoRef.current,
      rizwanScriptStep: rizwanScriptStepRef.current, 
       rizwanStatus: aiFormData.enable_rizwan_event==='yes' ? true : false || false ,
      //rizwanStatus: true 
    };
    socket.emit("startGoogleCloudStream", data);

    });

    // socket.emit("startGoogleCloudStream");

    socket.on("receive_audio_text", (data) => {
      
      console.log("received audio text", data);


      // if(isAudioPaused.current === false){

        speechRecognized(data);

       setUserSilent(true);    

      //}

      
    });

    socket.on("disconnect", () => {
      console.log("disconnected", socket.id);
    });
  };


  const disconnect = () => {

    //console.log("!isUserSilent.current",isUserSilent.current)    

  

     if(aiFormData.enable_rizwan_event!=='yes' && aiFormData.enable_face_detection!=='yes'){
      document.getElementById("ai_starttalk").style.display = 'block';
      document.getElementById("stoprecording").style.display = 'none';
    }

    if (!connection) return;
    
    connection.emit("endGoogleCloudStream");
    connection.disconnect();
    processorRef.current?.disconnect();
    audioInputRef.current?.disconnect();
    audioContextRef.current?.close();
    setConnection(null);
    setIsRecording(false);
    
  
    if (recordingTimeoutRef.current) {
      clearTimeout(recordingTimeoutRef.current);
    }
    if (inactivityTimer.current) {
      clearTimeout(inactivityTimer.current);
    }
    if (silenceTimer.current) {
      clearTimeout(silenceTimer.current);
    }
  };

  useEffect(() => {
    resetInactivityTimer(); // Start inactivity timer when component mounts

    return () => {
      if (isRecording) {
        processorRef.current?.disconnect();
        audioInputRef.current?.disconnect();
        if (audioContextRef.current?.state !== "closed") {
          audioContextRef.current?.close();
        }
      }
      if (inactivityTimer.current) {
        clearTimeout(inactivityTimer.current);
      }
      if (silenceTimer.current) {
        clearTimeout(silenceTimer.current);
      }
    };
  }, [isRecording]);

  useEffect(() => {
    (async () => {
      if (connection && !isRecording) {
        const stream = await getMediaStream();

        audioContextRef.current = new window.AudioContext();

        await audioContextRef.current.audioWorklet.addModule(
          "/src/worklets/recorderWorkletProcessor.js"
        );

        audioContextRef.current.resume();

        audioInputRef.current =
          audioContextRef.current.createMediaStreamSource(stream);

        processorRef.current = new AudioWorkletNode(
          audioContextRef.current,
          "recorder.worklet"
        );

        processorRef.current.connect(audioContextRef.current.destination);
        audioContextRef.current.resume();

        audioInputRef.current.connect(processorRef.current);

        processorRef.current.port.onmessage = (event) => {
          const audioData = event.data;
          connection.emit("send_audio_data", { audio: audioData });
        };

        setIsRecording(true);

        if(aiFormData.enable_rizwan_event!=='yes'){

          console.error("No repe");

          // Automatically stop recording after 10 seconds
            // recordingTimeoutRef.current = setTimeout(() => {
            //   disconnect();
            // }, aiFormData.speech_time_limit*1000); 

        }

        

        resetInactivityTimer(); // Start inactivity timer when recording starts
        resetSilenceTimer(); // Start silence timer when recording starts
      } else {
        console.error("No connection");
      }
    })();
    return () => {
      if (isRecording) {
        processorRef.current?.disconnect();
        audioInputRef.current?.disconnect();
        if (audioContextRef.current?.state !== "closed") {
          audioContextRef.current?.close();
        }
      }
    };
  }, [connection, isRecording]);


  const rizwanButtonFunRun=()=>{

    if(rizwanScriptStepRef.current===1 && aiFormData.enable_rizwan_event==='yes'){

    const rizwanStatus = aiFormData.enable_rizwan_event==='yes' ? true : false;
    rizwanButtonFun(onsessionInfoRef.current,rizwanStatus);

    }


  if(rizwanScriptStepRef.current===2 && aiFormData.enable_rizwan_event==='yes'){
      
    setonstartspeech();
  }
  
  }

  return (
    <div>
      {aiFormData?.enable_speech_btn==='yes' && (
        <>

       
         
          <button type="button" className="ai_starttalk" id="ai_starttalk" style={{ 
            backgroundColor: siteBrandColor, 
            display: aiFormData.enable_rizwan_event==='yes' || aiFormData.enable_face_detection==='yes' ? 'none' : ''
            
          }}  
          onClick={()=>{
            setAudioPaused(false);

            
            connect();
            setUserSilent(false);  
          
          }}>
          {waitingTime ? 'Please Wait...' : translationData?.ask_your_question}
    
          </button> 
      
    
          <button type="button" className="ai_starttalk" id="stoprecording" style={{ 
            backgroundColor: siteBrandColor, 
            display: aiFormData.enable_rizwan_event==='yes' ? 'none' : ''
            
            }} onClick={disconnect}>
          {currentRecognitionLangListenRef.current ? currentRecognitionLangListenRef.current: 'Listening...'}
          </button>


         {(rizwanScriptStepRef.current===1 || rizwanScriptStepRef.current===2) && aiFormData.enable_rizwan_event==='yes' && aiFormData?.enable_rizwan_btn==='yes' && (

            <button type="button" className="ai_starttalk"  style={{ 
              backgroundColor: siteBrandColor           
              
              }} onClick={()=>rizwanButtonFunRun()}>
            {rizwanScriptStep===1 ? aiFormData?.enable_rizwan_btn_text_1 : aiFormData?.enable_rizwan_btn_text
}
            </button>
         )}
          
          
     

      <div id="textresponse" style={{ fontSize: aiFormData.enable_rizwan_event==='yes' ? '42px' : '20px'}}>
      {
        aiFormData.speech_text_response === 'yes'  && (
          aiFormData.enable_rizwan_event === 'yes' && rizwanScriptStepEnglishRef
            ? currentText
            : aiFormData.enable_rizwan_event === 'yes' && !rizwanScriptStepEnglishRef ? '' : currentRecognitionRef.current
  
            )
            
        
  
          
      }

      </div>
      </>

      )}
      
    </div>
  );
};

export default AudioToText;
